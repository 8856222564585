export interface PostType {
    id: string;
    title: string;
    description: string;
    date: string;
    content: string;
}

export const posts: PostType[] = [
    {
        id: '100-lessons',
        title: '100 Lessons from 1 Year of AI Research',
        description: 'Lessons from my Undergraduate Research Opportunities Programme at NUS.',
        date: 'Dec 2021',
        content: `# About these Lessons

I'm an undergraduate from the National University of Singapore. I researched reinforcement learning for a full year, including full-time over winter and summer breaks, as part of an undergraduate research [programme](https://www.comp.nus.edu.sg/programmes/ug/project/urop/). I faced many uncertainties and difficulties while doing research over the year, and deliberated over choosing to spending time on research versus on industry-relevant skills.

This blog post condenses all the hard-earned lessons I've learnt after stumbling through mistakes and picking myself up again. After 1 year of research, I believe that my decision to spend 1 year’s worth of time on research was well worth it, purely from my takeaways, even though I ended up without a publication that I desired so much at the start of my journey. In writing this post, I hope that fellow researchers that face related challenges about research can learn without going through the hard way, and I believe that this post will be helpful to refer back to when stuck on various difficulties related to research.

This post is structured by lessons on:

1. Research - *Insights about research methodology*
2. Learning - *Reflections about learning effectively*
3. Reinforcement Learning - *Difficulties I faced with my research topic*
4. Workflow - *Hard-earned lessons that incurred significant costs*
5. Motivation - *Introspections about how to advance myself sustainably*
6. Support - *Acknowledgements for the social structures that enabled me*
7. Mindset - *Hard-earned lessons about life that I learnt from research*

Each lesson consists of an advice, a brief explanation and the lesson through which I learnt it.

I would like to thank my research advisor [Prof. Harold Soh](https://haroldsoh.github.io/) from the [Collaborative, Learning and Adaptive Robots (CLeAR) Lab](https://clear-nus.github.io/) at NUS for his mentorship, my seniors in the lab Bingcai and Kaiqi for their advice, my close friends Shiying, Jun Jie, Wai Ching, Ming Liang and Liying for their support, and many other people who have influenced and helped me in this short but memorable 1-year journey.

I am also looking for a research internship in 2022, ideally reinforcement learning; please [let me know](mailto:notesjet@gmail.com) if you are recruiting!

# Research

1. The problem statement is the most important thing in research because without one, there's no real need for the research idea.
2. Develop a long-term vision of your research direction to avoid tunnel-visioning into a few specific lines of research.
3. Reflect on the significance of problems that papers aim to address as there exists papers that explore ideas without addressing a real problem.
4. When reading papers, reflect about the magnitude of improvement made towards addressing the targeted problem.
5. Understand the significance of performance improvements with respect to the problem, such as the units or measure used.
6. When reading papers, analyse closely how the authors overcame difficulties to make the idea work, because papers tend to highlight successes, unintentionally downplaying the difficulties needed to be overcome to make the proposed idea work.
7. When evaluating ideas in papers, consider the compute and space required, and whether you can afford to try those ideas given your constraints.
8. Simple, elegant ideas are preferable to complex, "Frankenstein" ideas that can seem more like a series of hacks.
9. Try to work towards significant innovations instead of "*delta improvements*" that generates only little or even negligible insights.
10. Research experiments should be minimally sufficient and target the hypothesis well so that time is not wasted due to redundant results.
11. Research papers are about developing insights, not chasing performance improvements, since significant insights will lead to many subsequent papers.
12. During idea generation, focus on how you'd solve problems and *less* on others' existing solutions.
13. Evaluate problem significance not just on small "toy problems", as problems could turn out massive when scaled up or completely vanish in a real-world setting.
14. Reflect on difficulties in your research field even if they may not be directly related to your targeted problem as those may eventually hinder your progress if left neglected.
15. Don’t just read or you'll never get started. At some point, start formulating potential solutions to problems.
16. Consider the time requirements when scoping your problem, idea or experiment, as working on them may turn out infeasible with limited resources.
17. Invest time in learning how to do research and not just on your research topic.
18. Choose a research topic that your lab specialises in, not one that is indirectly related, or choose a lab according to your research interests.
19. A research idea that is elegant is one that is minimally sufficient.
20. Avoid being overambitious with a research idea, otherwise, chances are that the method won't work or it works but you don't understand why.
21. Always situate your problem, motivation and experiment design around existing literature to enable comparison and context to justify design choices.
22. Don't take it personally if an idea that seems justified initially turns out ineffective, since not all ideas work well, yet some simple ideas can be unreasonably effective.

# Learning

23. Ensure a strong mastery of foundations.
2. Don't shy away from difficult topics — break them down into more manageable components, focusing on the years-long goal of mastery.
3. Keep updated with research papers published across multiple different conferences.
4. Frequently consolidate knowledge you learnt, through summaries and mindmaps.
5. After reading many papers, write a survey to organise the structure of existing literature and synthesise current research problems, methods and directions.
6. Especially important for complex topics, create a mindmap for the large diversity of existing problems, tasks and approaches in your field of research for an overview perspective.
7. Summarise papers and their key contributions to enable efficient revision and future reference.
8. Teach and present paper summaries to others to ensure you understand the nuances of the paper and get other perspectives that you might not have considered.
9. Invest time in learning topics in adjacent or relevant fields, even if they may not be immediately related.
10. Before attempting to generate potential paper ideas, ensure that you have a robust understanding of the current literature, otherwise time may be wasted when another paper might have already hinted at its ineffectiveness.
11. Remember that online resources for explanations and code walkthroughs are available.
12. Before formulating ideas, dive into the theory to thoroughly understand the nuances between the theory of different works.
13. Invest time in software development skills.
14. Explore and work on ideas from adjacent fields; it usually brings new insights into your research.
15. Don't shy away from theory papers that seem difficult initially; they are a treasure trove of learning opportunities.
16. Be daring to express opinions and ask questions that you're uncertain about, so as to receive feedback that directly updates your views.
17. Keep updated with "key highlight" papers in adjacent fields, as such influential ideas may eventually reach into your research topic.

# Reinforcement Learning

40. Remember to consider variance of results and the usage of random seeds.
2. Algorithms used for baseline comparisons should be evaluated with proper hyperparameter tuning.
3. Consider the large number of possible hyperparameter configurations in experiment formulation.
4. List all possible modes of failure after every experiment so that you can diagnose later problems easier.
5. Bugs in the code are more likely to be the cause of unexpected inferior performance rather than the idea itself.
6. Scope your research project according to available resources, such as focusing on problems that fundamentally require less compute, e.g. Data-Efficienct RL.
7. Inspect papers’ design choices thoroughly, as other options are likely to be less ideal.
8. Scope your project to focus on and analyse only a few selected components necessary to target the problem of interest, especially if it has many interacting components, e.g. in model-based reinforcement learning.
9. Be aware of research challenges present in your research field, e.g. reproducibility and benchmarking.

# Workflow

49. Justify experiment steps with the hypothesis it aims to answer, so that you don't waste time on experiments that don't generate insights.
2. Begin with simpler or smaller experiments to validate problems and ideas before moving to more complex and bigger ones.
3. Remember to parallelise experiments, even using CPUs alongside GPUs can be very useful.
4. Always consider all computational resources that are available to you and utilise them to the fullest, such as cloud compute credits.
5. Use a dedicated reference manager while reading papers, e.g. Zotero or Mendeley.
6. Invest time in setting up proper experimental tools and configurations, e.g. logging, Weights & Biases, code refactoring, as the time cost pays off much earlier than you expect.
7. Categorise the papers you've read properly, such as through tags using a reference manager.
8. Track dependencies and environment configurations of your project's code.
9. Invest time in learning to use Linux and set up servers.
10. Organize your work environment, be it your physical desk and research papers, your digital notes and PDFs, or your saved Tweets and bookmarks.
11. Don't necessarily work and update your advisor week after week, since ideas sometimes require more than 1 week to develop before the justification seems sound.
12. Make it a habit to work on research a few hours a day, even if you seem to make no progress.
13. Employ a systematic approach to reading papers, such as annotating or writing summaries.
14. Set deadlines on each individual task and keep to them strictly.
15. Narrow down and formulate your research project exactly, so as to better structure your research methodology in the short term.
16. Remember to clear space from trash folders.
17. If you can, work on only a few commitments at a time, so that you have free time and space to digest, synthesise and be creative with your research.
18. Invest time in improving your workflow across all levels of work, e.g. note-taking frameworks, work-break sprints, or simply clearing your desk at the end of each day.
19. Make sure that you understand everything before extending an existing code base, as one modification can affect another component unexpected, or even worse, fail silently.
20. Commit a fixed ratio of time to explore topics adjacent to your research field.

# Support

69. A community makes the journey of research easier.
2. Be daring to reach out for help from seniors and more experienced people, even if it may seem trivial.
3. Reach out to researchers to ask questions or request for help about the papers they author.
4. Reach out often to fellow researchers to discuss ideas, especially those whose work you admire.
5. Reach out to professors in your university; don't wait for a dedicated research programme to get started with research.
6. Research is not the only thing that matters in life; remember to take a break once in a while to spend time with family and friends.
7. Try to bounce ideas off other people in your lab to get their perspectives.
8. Get in touch with other researchers working in the same field as you, such as other professors in your university.
9. Keep frequent contact and maintain a good relationship with your research advisor.

# Motivation

78. Remember to review and acknowledge the progress you've made and successes you've achieved.
2. Celebrate small wins consistently.
3. Use paper submission deadlines as a goal to work towards and be motivated about.
4. Remember that the goal of publishing should only be used for motivation and not your main objective of research.
5. Don't fall prey to personal comparisons with other researchers, such as differences in competence, starting points, resources or available guidance, because everyone's journey is unique. 
6. Remember to maintain your health and fitness.
7. Know what motivates you and learn to manage your motivation.

# Mindset

85. Great things take time.
2. It's okay not to know many things; you only need to know a few things deeply.
3. Don't let yourself be too affected by the opportunity costs of doing research.
4. Be persistent on hammering at the problem and don't give up until you've exhausted all possible modes of failure, because research problems *are* difficult.
5. It's alright to not achieve concrete achievements such as publications, especially in your first few years of research.
6. Stop thinking about the past and less-ideal starting points that you possibly had, and focus on what you can do now to improve yourself from here onwards.
7. Be humbled by the difficulty and complexity of research; don't be overconfident about your abilities.
8. Know your strengths, embrace your weaknesses, and then leverage your strengths as much as possible.
9. Research has a long-horizon and sparse-reward credit assignment problem, as results may not show until months or even years later.
10. Don't work on ideas simply because you think they might work. Instead, work on ideas that you are passionate about and it will show in your work.
11. Sometimes, your best is not enough. And that's alright.
12. Focus on doing your best work, not a work that is better than others.
13. Research is but one aspect of life.
14. Your best idea is always your next idea, so if your current idea didn't turn out well, look forward to your next idea and move on.
15. Instead of focusing on failure, focus on the lessons you'd learnt in the process.
16. You never really stop learning lessons.`
    },
    {
        id: 'getting-started-rl',
        title: 'Getting Started with Reinforcement Learning',
        description: 'A curation of resources for reinforcement learning.',
        date: 'Jul 2021',
        content: `## What is Reinforcement Learning (RL)?

You may have heard about the successes of artificial intelligence (AI) over humans in games such as Go ([Go master quits because AI 'cannot be defeated'](https://www.bbc.com/news/technology-50573071)), StarCraft ([DeepMind's StarCraft 2 AI is now better than 99.8 percent of all human players](https://www.theverge.com/2019/10/30/20939147/deepmind-google-alphastar-starcraft-2-research-grandmaster-level)) and DoTA 2 ([OpenAI's Dota 2 AI steamrolls world champion e-sports team with back-to-back victories](https://www.theverge.com/2019/4/13/18309459/openai-five-dota-2-finals-ai-bot-competition-og-e-sports-the-international-champion)).

The core technology behind these AI successes is reinforcement learning. Reinforcement learning is a [machine learning](https://en.wikipedia.org/wiki/Machine_learning) paradigm (alongside supervised and unsupervised learning) that learns an optimal sequence of actions to take in the environment. In contrast, [supervised learning](https://en.wikipedia.org/wiki/Supervised_learning) learns a single output prediction based on an existing training dataset of labels, and [unsupervised learning](https://en.wikipedia.org/wiki/Unsupervised_learning) learns the distribution of data without any given labels.

As you may have expected, reinforcement learning is closely related to (and is in fact inspired by) the same concept behind [Pavlov's Dog](https://en.wikipedia.org/wiki/Classical_conditioning), operant conditioning. [Operant conditioning](https://en.wikipedia.org/wiki/Operant_conditioning) is a learning process that associates and modifies behaviours with reinforcement or punishment. This is well described by psychologist [Edward Thorndike](https://en.wikipedia.org/wiki/Edward_Thorndike)'s [Law of Effect](https://en.wikipedia.org/wiki/Law_of_effect), which states:

> "Responses that produce a satisfying effect in a particular situation become more likely to occur again in that situation, and responses that produce a discomforting effect become less likely to occur again in that situation."

The process of reinforcement learning takes place in 4 stages:
1. The environment's state is first observed by the agent.
2. The agent takes an action based on the observation.
3. The agent's action influences the environment's state.
4. The environment then returns a new state to the agent, along with a reward indicating the goodness of the action taken.

Reinforcement learning is currently used by a few applications in industry, namely [recommending personalised advertisements](https://venturebeat.com/2021/02/23/how-reinforcement-learning-chooses-the-ads-you-see/), improving search engines, and more recently, [improving pricing algorithms](https://engineering.grab.com/understanding-supply-demand-ride-hailing-data) of ride-hailing applications based on live demand and supply. While RL currently does not have as many applications as domains like [computer vision](https://en.wikipedia.org/wiki/Computer_vision) and [natural language processing](https://en.wikipedia.org/wiki/Natural_language_processing), RL is a promising and upcoming method with high potential in fields like [robotics](https://bair.berkeley.edu/blog/2020/04/27/ingredients/) (think automating thousands of manual processes in factories), self-driving vehicles (think reducing accident rates while significantly improving logistics transportation efficiency) and, of course, video games (who doesn't enjoy seeing games being solved/mastered?)

Reinforcement learning can seem quite intimidating to beginners due to the vastness of the field. In this blog article, I will share the resources and some advice on how to use them effectively to get started with learning reinforcement learning. These recommendations are heavily opinionated based on my own learning journey in RL.

***

## Introductory Reinforcement Learning

Reinforcement learning is a sub-field of machine learning, and so learning RL will require learning machine learning. You should also learn RL in consideration of mathematical theory and practical coding. The following 3 resources are thus recommended based on those considerations.

### 1. [Machine Learning Crash Course](https://developers.google.com/machine-learning/crash-course/ml-intro) by Google

Reinforcement learning requires many concepts from machine learning (ML). This crash course (that contains mainly explanatory videos) provides an introductory understanding to the main ML concepts that you will need for RL. Some concepts covered: Training vs Validation vs Testing, Classification vs Regression, Introductory Neural Networks.

### 2. [Reinforcement Learning: A Tutorial](http://www.cs.toronto.edu/~zemel/documents/411/rltutorial.pdf) by Harmon & Harmon

This tutorial presents the theory of RL at an introductory level. It formalises the RL paradigm with math, and is a great resource so that concepts are rigorous and not too hand-wavy. The math and notation may make it look intimidating, but if you understand the underlying motivation and concepts, understanding the notation will come naturally. Follow through thoroughly and you will be rewarded well. Topics covered: Dynamic programming, Value iteration, Q-learning, Temporal difference learning, Discounting rewards. Nonetheless, even if you're unable to follow through, you should still face no problem proceeding with the next resource on Medium.

### 3. [Deep RL Course on Medium](https://simoninithomas.github.io/deep-rl-course/) by Thomas Simonini

This Medium series on deep RL (deep because it uses deep neural networks) is one of the best courses for learning introductory deep RL with a good balance of practical coding and RL theory. Topics covered: Deep Q-learning, Policy Gradients, Actor Critics, Unity-ML Agents. The resources up to actor critics are excellent; Learning Unity-ML Agents is optional, learn it if you are interested in the Unity-ML Agents environments.

***

## Tools for Reinforcement Learning

### 1. RL Gym Environments

RL Gym environments are the simulation software that is used to train RL agents in. They're called "gyms" because of [OpenAI gym](https://gym.openai.com/)'s standardisation of environment APIs. There are too many RL environments, but here are some lists to refer to: [awesome-rl-envs](https://github.com/clvrai/awesome-rl-envs), [env-zoo](https://github.com/tshrjn/env-zoo) and [awesome-deep-rl](https://github.com/kengz/awesome-deep-rl). The RL environments are diverse and many are really cool, such as IKEA furniture assembly, Retro games and autonomous vehicle simulators.

### 2. RL Frameworks

RL frameworks are frameworks made specifically for building RL agents and/or training them. While most RL research work use general deep learning frameworks, e.g. [PyTorch](https://pytorch.org/), [TensorFlow](https://www.tensorflow.org/), [JAX](https://jax.readthedocs.io/en/latest/index.html), [FLAX](https://github.com/google/flax), [Pytorch Lightning](https://www.pytorchlightning.ai/), [TensorFlow Probability](https://www.tensorflow.org/probability), there are also RL frameworks available. There is currently no established "best" RL frameworks yet due to the diversity of RL concepts, but I found [Stable Baselines](https://stable-baselines3.readthedocs.io/en/master/) (recently version 3) very useful for basic usage of RL. [TensorFlow Agents](https://www.tensorflow.org/agents) is a library used by some companies in industry. [minimalRL](https://github.com/seungeunrho/minimalRL) and [CleanRL](https://docs.cleanrl.dev/) are repositories of minimal code implementations of RL algorithms in PyTorch that is useful for learning.

***

## Intermediate Reinforcement Learning

Reinforcement learning is a vast field that contains many different concepts (many inspired by cognitive neuroscience). Behaviours are influenced and controlled by many mechanisms and so many methods are available in reinforcement learning. This section will provide some tips on exploring the many domains in RL, e.g. Exploration, Memory, Model-based. It is important to note the difference between "RL" and "deep RL", the former being about traditional RL while the latter about applying neural networks to RL.

### 1. [Reinforcement Learning: An Introduction](http://incompleteideas.net/book/the-book.html) by Richard S. Sutton and Andrew G. Barto

Sutton and Barto is a good **textbook** on RL that is commonly coined the RL "bible" for its prominence. The textbook is a comprehensive resource and reference for RL, covering tabular methods such as multi-armed bandits, dynamic programming, Monte Carlo methods, TD-learning and bootstrapping, to approximate methods for prediction and control and policy gradients.

### 2. [Introduction to Reinforcement Learning](https://deepmind.com/learning-resources/-introduction-reinforcement-learning-david-silver) by David Silver, DeepMind

Intro to RL by David Silver, DeepMind is a good **lecture** series on reinforcement learning. It formulates RL with concepts about Markov decision processes, planning by dynamic programming, prediction vs control, value function approximation, among others. David Silver is a RL pioneer who co-authored [Playing Atari with Deep Reinforcement Learning](https://www.cs.toronto.edu/~vmnih/docs/dqn.pdf), one of the first successful attempts to apply deep neural networks to reinforcement learning.

### 3. [Reinforcement Learning Specialization on Coursera](https://www.coursera.org/specializations/reinforcement-learning) by University of Alberta

The RL Specialization on Coursera is a good **course** on RL, introducing concepts such as temporal difference learning, Monte Carlo, Sarsa, Q-learning, policy gradients, the Dyna architecture, and more. The course is taught by Martha White and Adam White, who are students of Richard Sutton, the author of the Reinforcement Learning: An Introduction textbook ([who personally recommended the course](https://www.reddit.com/r/MachineLearning/comments/h940xb/what_is_the_best_way_to_learn_about_reinforcement/)).

### 4. [Spinning Up: Key Papers in Deep RL](https://spinningup.openai.com/en/latest/spinningup/keypapers.html) by OpenAI

Spinning Up by OpenAI is a resource that introduces deep RL concepts to people interested in picking up RL. Their Key Papers in Deep RL section provides a good starting point to the overview of RL topics, introducing milestone research papers in each topic. Examples: Model-Free RL, Exploration, Transfer and Multitask RL, Hierarchy, Memory, etc.

### 5. [CS285: Deep Reinforcement Learning](http://rail.eecs.berkeley.edu/deeprlcourse/) by Sergey Levine, UC Berkeley

CS285 is a good **lecture** series on deep reinforcement learning, with concepts covering policy gradients, actor-critics, model-based RL, exploration, offline RL, inverse RL and meta-learning in RL, among others. Offline RL is used to train an RL agent offline (i.e. collect a batch of interactions then training the agent on it). Inverse RL involves using interaction data from a human expert and is used to infer the RL policy underlying the human decision making process. Sergey Levine is a prominent RL researcher and his course provides many good insights into deep RL.

### 6. [Algorithms for Decision Making](https://algorithmsbook.com) by Mykel J. Kochenderfer, Tim A. Wheeler and Kyle H. Wray

Algorithms for Decision Making is a good and updated (2022) **textbook** that serves as a modern perspective of the problems in the field of reinforcement learning. In particular, it delivers a detailed walkthrough of belief state planning and multi-agent systems compared to Sutton & Barto, and contains beautiful illustrations for explanations, complete with code implementations in Julia.

***

## Advanced Reinforcement Learning

Beyond the standard learning resources are the topics that are active and unsolved open research questions. At this point, the research community is your best friend. The following resources and advice are strategies I found useful in my own learning journey.

### 1. Survey Research Papers

While there are many research papers that are impossible to read them all, there are survey papers that provide an overview of the current research landscape and topics. These survey papers provide an outline of the field and importantly introduce terminology that you can use to further search for what you are interested in. For example, [Reinforcement Learning: A Survey](https://arxiv.org/pdf/cs/9605103.pdf) (Kaelbling et al. 1996) surveys the field of RL from a computer science perspective. For a more updated survey on deep RL, refer to [A Brief Survey of Deep Reinforcement Learning](https://discovery.ucl.ac.uk/id/eprint/10083557/1/1708.05866v2.pdf) (Arulkumaran et al. 2017). I am personally interested in model-based reinforcement learning, so the following surveys served me well: [Model-based Reinforcement Learning: A Survey](https://arxiv.org/abs/2006.16712) (Moerland et al. 2020), [Deep Model-Based Reinforcement Learning for High-Dimensional Problems, a Survey](https://arxiv.org/abs/2008.05598) (Plaat et al. 2020). If you are interested in other domains in RL such as exploration or memory, you can search for terms terms like "reinforcement learning exploration survey" or "reinforcement learning memory survey" on Google Search or [Google Scholar](https://scholar.google.com/).

### 2. Research Blogs / Articles

Blog posts are my personal favourite for developing an initial understanding of the research landscape or particular research papers. One of the most popular blogs is [Lilian Weng's blog](https://lilianweng.github.io/lil-log/2018/02/19/a-long-peek-into-reinforcement-learning.html), that covers many topics including RL, natural language processing, computer vision and representation learning. [Berkeley AI Research (BAIR)'s blog](https://bair.berkeley.edu/blog/), [Google AI Blog](https://ai.googleblog.com/) and [Facebook AI Research (FAIR)'s blog](https://ai.facebook.com/blog/?page=1) often blog about their RL papers. [Distill](https://distill.pub) was a peer-reviewed journal (not a blog) that focuses on interactive articles about machine learning research topics, allowing for playable exploration on their articles.

### 3. YouTube Channels

YouTube channels also provide good videos that explain papers or provide updates on the state of research. [Yannic Kilcher](https://www.youtube.com/channel/UCZHmQk67mSJgfCCTn7xBfew) is a well-known YouTuber for his videos that explain recent hotly discussed papers. [Henry AI Labs](https://www.youtube.com/channel/UCHB9VepY6kYvZjj0Bgxnpbw/videos) provide weekly updates about AI in general. [Machine Learning Street Talk](https://www.youtube.com/channel/UCMLtBahI5DMrt0NPvDSoIRQ) conducts interviews with researchers to discuss about their research.

### 4. Online Communities

Various online communities are active for research discussions as well, such as communities on Twitter or Reddit. Online communities are important because they foster discussion, debate and ideation. Twitter (or "Academic Twitter") is a highly active community because many researchers tweet about their research. A list of researchers to follow on Twitter: [here](https://www.reddit.com/r/MachineLearning/comments/b6dwrd/d_best_current_twitter_accounts_to_follow_for/), but do actively adjust your Twitter feed according to your research interests. [r/MachineLearning](https://www.reddit.com/r/MachineLearning/) is an active community that posts interesting research papers or discussion topics about ML research in general with thought-provoking comments. Or you could start your own a reading group!

***

## Conclusion

Reinforcement learning, as you now know, is such a vast and diverse field by nature that is still a highly active research area with many open problems. While RL is currently mainly used for ads, search and recommendation, RL holds great potential by unlocking automation of robotics, driverless vehicles and many other applications. With this article, I hope that it helps more people get into reinforcement learning and unlock the many things that can be done with RL. That said, again, this is an opinionated article based on my learning journey, and likely will change as I progress in my learning. If you have recommendations for resources or tips to include in this article, feel free to contact me at [notesjet@gmail.com](mailto:notesjet@gmail.com). Cheers!`
    },
    {
        id: 'conduct-technical-workshop',
        title: 'Why You Should Conduct a Technical Workshop',
        description: 'Reflections on leading the Workshops Team at NUS Statistics Society in AY2020/21.',
        date: 'Mar 2021',
        content: `## What is a technical workshop?

A technical workshop is a short lesson, like a lecture or tutorial, that usually emphasises hands-on practice into a technical tool or concept. In 2020 and 2021, [NUS Statistics Society](https://sites.google.com/view/nusstatisticssociety/) conducted [8 technical workshops](https://sites.google.com/view/nusstatisticssociety/workshops) in total for NUS students and the wider community in [Data Science SG](https://www.meetup.com/datascience-sg-singapore/). In this article, I share why I think you should experience conducting a technical workshop, based on my experience over 1 year directing the 9-member strong [Workshop Team](https://sites.google.com/view/nusstatisticssociety/about/about-workshops) in the NUS Statistics Society 2020/21.

## A space for shared curiosity

Workshops create an environment of shared curiosity and enthusiasm around a particular topic. If a topic piques your interest, you can safely ask questions to the presenters or fellow students. In Oct 2020, a workshop on [Principal Component Analysis](https://sites.google.com/view/nusstatisticssociety/workshops/all-about-pca-theory-algorithm-application) (PCA), a machine learning algorithm, attracted 30 live students. Those who attended shared the doubts they had, and even raised questions that presenters haven't thought of before. Other students joined in the discussion and came up with an answer, teaching something new to the presenter. The recursion of students asking questions to the teachers and teachers learning from students and back again reinforces a dynamic relationship that strengthens everyone's learning in the topic. Creating a space where all learners can feel safe to learn new things is the main reason why I chose to direct Workshops at Statistics Society.

## Building confidence and professionalism

Workshops build a sense of confidence and professionalism that is robustly adaptable to unexpected situations. Each workshop member is responsible for the workshop that their group is conducting. In semester 1, everyone was assigned to workshops that were their "specialty", namely the SQL (a database query language), applied ML (machine learning) and ML theory workshops. Given prior knowledge in what everyone is supposed to present, everyone can instead focus on the workshop conducting skills required to run a workshop effectively. Behind every workshop is a full rehearsal a few days before.

## Managing the unpredictability of live events

But inevitably, technical difficulties occur. During the [SQL workshop](https://sites.google.com/view/nusstatisticssociety/workshops/sql-for-data-scientists-101) with a large crowd of 167 viewers, Agatha lost wifi connection, but Michael Yang stepped up to the occasion to take over the content his partner had prepared for the workshop (a full hour of content), given his prior knowledge in SQL and the familiarity having had a rehearsal with his partner. On the other hand, Agatha, despite having prepared hours of effort in the workshop, dealt with the inevitable with grace, by itself is an admirable feat. Technical issues are a nature of live and online events, and to overcome them is an ability that only those who have gone through them can acquire.

## A sense of ownership

Workshops create a strong portfolio and ownership towards the conduct of the workshop and the end-product of the workshop. In the [applied ML workshop](https://sites.google.com/view/nusstatisticssociety/workshops/applied-machine-learning-for-face-mask-detection) of 57 viewers, Georgie and Rama led in a hands-on demo to build an end-to-end face mask detection web app, including the initial machine learning training. Rama created a handy Jupyter notebook that compiled the training pipeline, from feature extraction, transfer learning, model training and evaluation, complete with elaborate documentation. Georgie constructed the backend to connect the face mask detector to a user-facing web app, with optimised latency to detect whether a person is wearing a mask in real time. The resources created by them are [freely available](https://github.com/geoboom/facemask-workshop) and serves as a useful indicator to future employers that represent their capabilities.

## On leadership as a necessity

Leadership is one of the core necessities in any endeavour. Before directing workshops at NUS Statistics Society, I was a workshop member at [NUS Developer Student Club](https://dsc.comp.nus.edu.sg/) in AY2019/2020, presenting [a series of 5 data science workshops](https://www.youtube.com/playlist?list=PLiAp0_yuG0tY3bldy2K3L3s5XZmlmy8Gu), including one at Google Developer Space.

What made me make the switch to Statistics Society was the then and current president Joel Tan, who emphasised the flexibility of members to lead in their own style, anything from allowing us to decide the size of our team to the many new initiates we could try implementing over the year. This focus on member flexibility was shared across the society, and expressed in the many new initiatives we kick-started, such as [memes on Instagram](https://www.instagram.com/nusstatssoc/), [publishing member-curated articles](https://sites.google.com/view/nusstatisticssociety/articles), a workshop collaboration with the [psychology department](https://sites.google.com/view/nusstatisticssociety/workshops/effective-data-visualization) and [Data Science SG community](https://datascience.sg/), badminton sessions, and a monthly internal paper sharing club within the society, among many others. With good leadership, a good experience in the CCA will naturally follow. If this interests you, we are [currently recruiting the next batch of leaders](https://nus.campuslabs.com/engage/submitter/form/start/473251) in the society!

## Recruitment as a commitment

During the recruitment for the workshop team, I faced a difficulty - 8 people made it through my interview (difficult as described by many), but most teams only had at most 5 members. Here is the dilemma: Having a large team can mean some members might not be meaningfully engaged if there wasn't much work to do, while planning more work to do means that much more responsibility and organisation was required by me to commit to over the year. After much consideration, I decided against randomly selecting an arbitrarily smaller number of members, as it was unfair to those who did qualify but were not selected, accepted all 8 people, and readied to commit to more work. With a large 9-man strong team, the amount of work was great, but I believe the team felt was especially fulfilling as we conducted 8 impactful workshops with over 800 peak unique concurrent workshop participants, across the short span of a year!

## Experience over outcomes

As a society, the shared value across the society emphasises on the experience of members. However, due to the COVID-19 pandemic, there were very few opportunities to meet up in person, much less for team bonding. In fact, all meetings in semester 1 were conducted online and all workshops across the year were conducted online.

As a commitment towards a good experience for members, we allocated a part of our societal budget to subsidise team lunches once a semester, which helped with bonding in the society. At the end of 2020, when COVID-19 restrictions were reduced, we went for our first in-person team dinner. Nonetheless, what I think makes a bonded team is not the benefits that an organisation provides, but the willingness of each member to commit to the team. The purpose of joining a student organisation has never been for monetary gain or reputational benefits (there really isn't much). It is not to achieve a certain outcomes, e.g. "I want to execute X number of workshops this year", but rather to subscribe to a fun and enjoyable experience for everyone. Despite many COVID-19 difficulties, we persisted and forged many strong friendships across the society.

## Tips on planning a workshop

Having organised a year's worth of workshops, here are some tips I've learnt about planning for a workshop and what I would change if I could go back in time.

Firstly, and arguably most importantly, workshops should aim to be beginner-friendly and have as few pre-requisites as possible. Workshops are not meant to be a crash course -- they are meant to be an introductory taste to pique the learners' interest and curiosity to further explore the subject on their own. This is what I would improve on if I could turn back time. Some of our workshops were admittedly not beginner-friendly, leading some students to be lost midway in the workshop.

Secondly, workshops should be timed near the start of the academic semester, between weeks 1 to 3, or a less ideal but still alright recess week. In all other weeks, students are focusing on assignment deadlines or exams, and will not turn up even if they do register for the event. An early workshop in the semester means the term break prior to the semester should be well-used for workshop preparation.

Thirdly, publicise your workshop by showing how your target audience will benefit from it. For example, students should learn SQL because it is highly relevant in many jobs from software engineers to data scientists. Showing the relevance of the workshop content will help potential participants understand how joining the workshop will be a good use of their time.

Fourthly, involve your participants with some form of hands-on activity. Spend some buffer time to let participants play around with an API or attempt to solve problems. In our SQL workshop, since learning SQL requires practice, we introduced sample SQL questions and allocated time for students to try their hand at them, and the feedback showed that the hands-on practice benefitted the students.

Lastly, try to keep the duration of the workshop to at most 1.5 hours. Most of the learners, even the presenter, will begin to tire and feel that the workshop is dragging on by the 1.5-hour mark.

If this has piqued your interest, we are also recruiting members for the next academic year very soon!

## Concluding

All in all, conducting a technical workshop is a great means of professional development in one's career, but the biggest takeaway is definitely the friends you meet and the memories forged. The past year has been extremely eventful and filled with challenging decisions, and I will continue to remember them for a long time. 

To connect with us, find us on [our website](http://sites.google.com/view/nusstatisticssociety), [Telegram channel](http://tinyurl.com/StatsTele), [Instagram](http://tinyurl.com/StatsTele), [LinkedIn](http://linkedin.com/company/nusstatssoc/) or our [society email](mailto:statistics.society@u.nus.edu).

---

### About the Author

Jet New is the Director of Workshops at NUS Statistics Society AY2020/21. He studies computer science + USP and researches on causal reinforcement learning.

### Workshop Statistics

| Workshop | Registrations | Peak Concurrent Viewers | Feedback (Submissions) |
|----------|---------------|-------------------------|------------------------|
| Machine Learning from Scratch | NA | 60 | - |
| SQL for Data Scientists 101 | 470 | 167 | 4.93 (15) |
| SQL for Data Scientists 102 | 470 | 51 | 4.90 (10) |
| Applied Machine Learning for Face Mask Detection | 183 | 57 | 4.50 (10) |
| All About PCA: Theory, Algorithm, Applications | 72 | 30 | 4.71 (7) |
| Data Science Competition: Applied Computer Vision | NA | 415 | 4.31 (13) |
| Effective Data Storytelling | 88 | 41 | 4.45 (11) |
| Using Tidyverse in R for Data Analysis and Visualisation | 83 | 27 | 4.33 (3) |
`
    }
];