import React from 'react';
import './App.css';
import { Linkedin, Twitter, Github, Mail, ExternalLink } from 'lucide-react';
import { BrowserRouter, Route, Routes, useNavigate, useParams, Link } from 'react-router-dom';
import { posts } from './components/Post';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

function Layout({ content }: { content: React.ReactNode }) {
  const navigate = useNavigate();
  return (
    <>
      <div className="py-16 max-w-2xl mx-auto min-h-screen flex flex-col">
        <div className="font-semibold text-lg pb-1 cursor-pointer hover:underline" onClick={() => navigate('/')}>Jet New</div>
        <hr />
        <div className="pt-4 flex space-x-12">
          <div className="flex flex-col space-y-1">
            <div className="cursor-pointer hover:underline" onClick={() => navigate('/about')}>About</div>
            <div className="cursor-pointer hover:underline" onClick={() => navigate('/projects')}>Projects</div>
            <div className="cursor-pointer hover:underline" onClick={() => navigate('/blog')}>Blog</div>
            <a href="https://agentscale.ai" target="_blank" rel="noopener noreferrer" className="cursor-pointer hover:underline flex">Services<ExternalLink className="pl-1 h-4 w-4" /></a>
          </div>
          {content}
        </div>
        <div className="pt-4 flex space-x-2 mt-auto">
          <a href="https://www.linkedin.com/in/jetnew" target="_blank" rel="noopener noreferrer">
            <Linkedin />
          </a>
          <a href="https://twitter.com/jetnew_sg" target="_blank" rel="noopener noreferrer">
            <Twitter />
          </a>
          <a href="https://github.com/jetnew" target="_blank" rel="noopener noreferrer">
            <Github />
          </a>
          <a href="mailto:jet@aetherlab.ai" target="_blank" rel="noopener noreferrer">
            <Mail />
          </a>
        </div>
      </div >
    </>
  )
}

function Home() {
  return (
    <Layout content={
      <div className="space-y-4">
        <p>🇸🇬 Hi! I'm an AI engineer based in Singapore, working on the intersection of RAG, agents and knowledge graphs.</p>
        <p>💻 I'm currently building <a href="https://atlas.aetherlab.ai" className="cursor-pointer underline hover:italic">Atlas</a>, a browser assistant to help people understand complex content better.</p>

        <p>💼 I've worked as:
          <ul className="list-disc pl-8">
            <li>Founder at AgentScale AI (2023): Research, Engineering, Product</li>
            <li>Data Scientist at Indeed (2022): Recommendation Systems</li>
            <li>AI Researcher at NUS (2021): Transformer and Reinforcement Learning</li>
            <li>Machine Learning Engineer at Grab (2021): Bayesian Machine Learning</li>
          </ul>
        </p>

        <p>🎓 I graduated in CS (Turing Programme, University Scholars Programme) from the National University of Singapore in 2023, as top student in machine learning.</p>

        <p>🧠 I run AI Space, an AI community in Singapore with ~1K people, e.g. at Google Developer Space.</p>
      </div>
    } />
  )
}

function About() {
  return (
    <Layout content={
      <div className="space-y-4">
        <div>
          <div>Founder, AI Engineer</div>
          <p className="text-sm">Atlas, Jun 2024 - Present</p>
          <ul className="list-disc pl-8 text-xs">
            <li>Atlas is a browser assistant to help people digest complex content.</li>
            <li>Atlas creates insightful mind maps to help you digest complex documents, research papers or even YouTube videos.</li>
          </ul>
        </div>
        <div>
          <div>Director, AI Engineer</div>
          <p className="text-sm">AgentScale AI, Jun 2023 - Present</p>
          <ul className="list-disc pl-8 text-xs">
            <li>AgentScale AI empowers businesses with cutting-edge automation solutions using robotic process automation, large language models and AI agents.</li>
          </ul>
        </div>
        <div>
          <div>Data Scientist</div>
          <p className="text-sm">Indeed, May 2022 - Jul 2022</p>
          <ul className="list-disc pl-8 text-xs">
            <li>Feature Experimentation: Improved Indeed's core recommendation system to match jobseekers and employers, by experimenting on a new "no. of hires needed" feature, improving job outcomes for &gt;300M jobseekers.</li>
            <li>Data Engineering: Added new features of &gt;300M jobseekers of &gt;100 days to training database, backfilling historical data over aggregated windows using Spark's reduce(). Orchestrated data dumping to AWS servers job using Airflow.</li>
            <li>Feature Analysis: Performed EDA and feature engineering on new features. Trained and evaluated 4 new models against original models using internal ML platform, showing improved AUROC and justifying a full A/B experiment.</li>
            <li>A/B Experimentation: Performed power analysis to determine required traffic size and ran A/B experiments using Proctor with orthogonal design to account for interaction effects. Deployed improved models to production.</li>
          </ul>
        </div>
        <div>
          <div>AI Researcher</div>
          <p className="text-sm">National University of Singapore, Nov 2020 - Apr 2022</p>
          <ul className="list-disc pl-8 text-xs">
            <li>Transformer Models: Improved generalization of world models during distribution shift in the multi-agent context, by exploiting permutation invariance of transformers.</li>
            <li>Representation Learning: Improved robustness of reinforcement learning to noise in visual observations, by learning decorrelated latent variables using Barlow Twins, exceeding performance of state-of-the-art algorithm Dreamer.</li>
            <li>Survey Paper: Reviewed representation learning for model-based reinforcement learning, challenges and directions.</li>
          </ul>
        </div>
        <div>
          <div>Machine Learning Engineer</div>
          <p className="text-sm">Grab, May 2020 - Jul 2020</p>
          <ul className="list-disc pl-8 text-xs">
            <li>Probabilistic Models: Built a probabilistic modelling framework used by Grab's core dynamic pricing algorithm, implementing 5 models using Tensorflow Probability (Gaussian mixture density network, Bayesian neural network, etc).</li>
            <li>Utility Tools: Built tools for evaluation on industry datasets (KL & JS divergence), visualization of model probability densities (3D and violin plots), and hyperparameter tuning using Ax Bayesian optimization framework.</li>
            <li>Internship Sharing: Presented to the data science community at Google Developer Space Singapore.</li>
          </ul>
        </div>
        <div>
          <div>Machine Learning Engineer</div>
          <p className="text-sm">Infocomm Media Development Authority, Nov 2018 - Jun 2019</p>
          <ul className="list-disc pl-8 text-xs">
            <li>Anomaly Detection: Designed an anomaly detection algorithm, achieving 0.92 F1 score and deployed as client's main solution, securing a $500K project deal, after evaluating &gt;10 algorithms (e.g. LSTM, Holt-Winters, SARIMA).</li>
            <li>Natural Language Processing: (Confidential) Built hierarchical attention network with GloVe vectors using Keras and Spacy, scraped 40K webpages using Selenium, and delivered insights to Director of Tech & Infra Group.</li>
          </ul>
        </div>
      </div>
    } />
  )
}

function Projects() {
  return (
    <Layout content={
      <>
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-2">
            <div className="text-sm">Startup</div>
            <div className="grid grid-cols-2 gap-2 w-full">
              <a href="https://atlas.aetherlab.ai" target="_blank" rel="noopener noreferrer" className="border rounded-md p-4 hover:shadow-md">
                <div>Atlas</div>
                <div className="text-sm">AI Browser Assistant</div>
              </a>
              <a href="https://agentscale.ai" target="_blank" rel="noopener noreferrer" className="border rounded-md p-4 hover:shadow-md">
                <div>AgentScale AI</div>
                <div className="text-sm">AI Automation Agency</div>
              </a>
              <div className="border rounded-md p-4">
                <div>Stealth Startup</div>
                <div className="text-sm">Enterprise-grade AI Video Model</div>
              </div>
              <div className="border rounded-md p-4">
                <div>Layla: AI Sales Rep</div>
                <div className="text-sm">Iterative Final Round Interview</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <div className="text-sm">Research</div>
            <div className="grid grid-cols-2 gap-2 w-full">
              <a href="/pdf/new2023fyp.pdf" target="_blank" rel="noopener noreferrer" className="border rounded-md p-4 hover:shadow-md">
                <div>Transformer World Models<br />for Ad Hoc Teamwork</div>
                <div className="text-sm">Honours Dissertation</div>
              </a>
              <a href="/pdf/new2021urop.pdf" target="_blank" rel="noopener noreferrer" className="border rounded-md p-4 hover:shadow-md">
                <div>Barlow Twins for Model-based Reinforcement Learning</div>
                <div className="text-sm">Turing Research Programme</div>
              </a>
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <div className="text-sm">Coursework</div>
            <div className="grid grid-cols-2 gap-2 w-full">
              <a href="/pdf/new2020slime.pdf" target="_blank" rel="noopener noreferrer" className="border rounded-md p-4 hover:shadow-md">
                <div>Multi-agent Reinforcement Learning for Slime Volleyball</div>
                <div className="text-sm">CS3244 1st Place at 17th NUS STePS</div>
              </a>
              <a href="/pdf/new2021structured.pdf" target="_blank" rel="noopener noreferrer" className="border rounded-md p-4 hover:shadow-md">
                <div>Structured Multi-Agent World Models</div>
                <div className="text-sm">CS4246 RL Best Project</div>
              </a>
              <div className="border rounded-md p-4">
                <div>Doodle-based Image Search</div>
                <div className="text-sm">CS4243 CV Best Project</div>
              </div>
              <a href="/pdf/new2021drfaq.pdf" target="_blank" rel="noopener noreferrer" className="border rounded-md p-4 hover:shadow-md">
                <div>DrFAQ</div>
                <div className="text-sm">CS4248 NLP Best Project</div>
              </a>
              <a href="/pdf/new2020trading.pdf" target="_blank" rel="noopener noreferrer" className="border rounded-md p-4 hover:shadow-md">
                <div>Idle Trading Hero</div>
                <div className="text-sm">NUS Orbital Artemis Achievement</div>
              </a>
              <a href="/pdf/new2020mask.pdf" target="_blank" rel="noopener noreferrer" className="border rounded-md p-4 hover:shadow-md">
                <div>COVID Agent-Based Modelling</div>
                <div className="text-sm">HackerNews Front Page</div>
              </a>
              <div className="border rounded-md p-4">
                <div>This Gemstone Does Not Exist</div>
                <div className="text-sm">CS6101 Guest Freshman Project</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <div className="text-sm">Hackathons</div>
            <div className="grid grid-cols-2 gap-2 w-full">
              <div className="border rounded-md p-4">
                <div>Polypeptide Generation</div>
                <div className="text-sm">Won $1K USD at LLM4Bio Hackathon</div>
              </div>
              <div className="border rounded-md p-4">
                <div>The Chosen One: Multi-agent RL</div>
                <div className="text-sm">Best AI Hack at iNTUition Hackathon</div>
              </div>
              <div className="border rounded-md p-4">
                <div>Carelytics</div>
                <div className="text-sm">HACKAsia Global Hackathon Finalist</div>
              </div>
              <div className="border rounded-md p-4">
                <div>Optigram</div>
                <div className="text-sm">1st Runner Up</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <div className="text-sm">Work</div>
            <div className="grid grid-cols-2 gap-2 w-full">
              <a href="/pdf/new2022indeed.pdf" target="_blank" rel="noopener noreferrer" className="border rounded-md p-4 hover:shadow-md">
                <div>Indeed Feature Experimentation</div>
                <div className="text-sm">RecSys for {`>`}300M Job Seekers</div>
              </a>
              <a href="/pdf/new2020grab.pdf" target="_blank" rel="noopener noreferrer" className="border rounded-md p-4 hover:shadow-md">
                <div>Grab Simkit</div>
                <div className="text-sm">Presented Google Developer Space</div>
              </a>
              <div className="border rounded-md p-4">
                <div>Chiller Doctor</div>
                <div className="text-sm">Secured $500K client deal</div>
              </div>
            </div>
          </div>
        </div>
      </>
    } />
  )
}


function Blog() {
  return (
    <Layout content={
      <div className="space-y-4">
        {posts.map(post => (
          <div key={post.id}>
            <Link to={`/blog/${post.id}`} className="hover:underline">
              {post.title}
              <div className="text-sm text-gray-500">{post.description}</div>
              <div className="text-xs text-gray-500">{post.date}</div>
            </Link>
          </div>
        ))}
      </div>
    } />
  )
}

function Post() {
  const { id } = useParams<{ id: string }>();
  const post = posts.find(post => post.id === id);

  if (!post) {
    return <div>Post not found</div>
  }

  return (
    <Layout content={
      <div className="flex flex-col">
        <div className="text-2xl font-semibold">{post.title}</div>
        <div className="text-sm text-gray-500">{post.description}</div>
        <div className="text-sm text-gray-500">{post.date}</div>
        <ReactMarkdown
          className="prose prose-sm max-w-none"
          remarkPlugins={[remarkGfm]}
          components={{
            h1: ({ node, ...props }) => <div className="text-xl font-semibold mt-4" {...props} />,
            h2: ({ node, ...props }) => <div className="text-lg font-semibold mt-4" {...props} />,
            h3: ({ node, ...props }) => <div className="text-md font-medium mt-4" {...props} />,
            ol: ({ node, ...props }) => <ol className="text-sm list-decimal list-inside my-4 mt-0 pl-4" {...props} />,
            ul: ({ node, ...props }) => <ul className="text-sm list-disc list-inside my-4 mt-0 pl-4" {...props} />,
            p: ({ node, ...props }) => <p className="text-sm mb-4" {...props} />,
            a: ({ node, ...props }) => <a className="underline hover:italic" aria-label={props.children ? undefined : 'Empty link'} {...props} />,
            td: ({ node, ...props }) => <td className="text-sm p-2" {...props} />,
            th: ({ node, ...props }) => <th className="text-sm font-semibold p-2" {...props} />,
          }}
        >{post.content}</ReactMarkdown>
      </div>
    } />
  )
}

function Contact() {
  return (
    <Layout content={
      <>
        <p>Email me at <a href="mailto:jet@aetherlab.ai" className="hover:underline">jet@aetherlab.ai</a>.</p>
      </>
    } />
  )
}

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<Post />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
